import React from 'react'

function StickyStream(){
    return(
        <div>
         <audio style ={{width: "100%"}} controls="controls" src="http://160.39.103.190:8000/stream" title="WBAR RADIO"></audio>
      
        </div>
    )
}
export default StickyStream